import { Outlet, Link } from "react-router-dom";
import "../component/style.scss"


function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

const Header = () => {
  return (
    <>
      <header>
        <div>
          <img src="favicon.ico" id="Logo" alt="Logo Coração de Viana" />
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/" onClick={scrollToTop}>Viana do Castelo</Link>
            </li>
            <li>
              <Link to="/industria" onClick={scrollToTop}>Indústria</Link>
            </li>
            <li>
              <Link to="/restaurante" onClick={scrollToTop}>Restaurantes</Link>
            </li>
            <li>
              <Link to="/lazer" onClick={scrollToTop}>Lazer</Link>
            </li>
          </ul>
        </nav>
        <Link to="/contato">
          <img id="logo-jn" src="JN_TECH_WE.png" alt="JN Tech Web" />
        </Link>
      </header>
      <Outlet />
    </>
  )
};

// Código para o header
window.addEventListener("scroll", function () {
  var header = document.querySelector("header");
  header?.classList.toggle('rolagem', window.scrollY > 500);
});

export default Header;
