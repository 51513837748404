import "../component/style.scss"

const Home = () => {
  return (
    <div>
      <div id="home-page">
        <div id="home-page-text" className="text-special">
          <strong>VIANA DO CASTELO</strong>
          Desbrave o Paraíso
        </div>
      </div>
      <div id="title1">
        <p id="title2">Viana do Castelo</p>
        <div id="text-home">
          <div>
            <p>Viana do Castelo é uma cidade encantadora situada na região norte de Portugal, banhada pelo Oceano Atlântico e abraçada pelo rio Lima. Sua história remonta a tempos antigos, com evidências de ocupação humana que datam da Idade do Ferro. No entanto, foi durante o período romano que a cidade ganhou destaque, conhecida como "Vicus Julii," devido à sua localização estratégica e conexão com a importante estrada romana Via Veteris.</p>

            <p>Durante a Idade Média, Viana do Castelo passou por várias mudanças de domínio, incluindo a ocupação muçulmana e, mais tarde, a cristã. No século XIII, foi concedido o primeiro foral (uma espécie de carta de privilégios) pelo rei Afonso III de Portugal, marcando o início de sua autonomia como cidade. O nome "Viana do Castelo" deriva do seu castelo medieval, que desempenhou um papel crucial na defesa da cidade contra invasões ao longo dos séculos.</p>
          </div>
          <div className="text-home-img1">
            <a title="Nsandre, CC BY-SA 3.0 &lt;https://creativecommons.org/licenses/by-sa/3.0&gt;, via Wikimedia Commons" href="https://commons.wikimedia.org/wiki/File:Gil_Eannes_Viana_do_Castelo.jpg" target="_blank" rel="noopener noreferrer">
              <img alt="Gil Eannes Viana do Castelo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Gil_Eannes_Viana_do_Castelo.jpg/512px-Gil_Eannes_Viana_do_Castelo.jpg" /></a>

            <p>A cidade prosperou durante a Era dos Descobrimentos Portugueses, quando muitos navegadores partiam de suas costas em busca de novas terras e rotas marítimas. O comércio marítimo trouxe riqueza e influência para Viana do Castelo, e seu porto tornou-se um dos mais importantes do país.<br />
              No entanto, Viana do Castelo também passou por momentos difíceis ao longo da história, incluindo invasões francesas durante as Guerras Napoleônicas e devastadoras epidemias no século XIX. No entanto, a cidade sempre se recuperou e manteve sua identidade cultural única.</p>


          </div>

          <div className="text-home-img2">
            <a title="Graeme Churchard from Bristol (51.4414, -2.5242), UK, CC BY 2.0 &lt;https://creativecommons.org/licenses/by/2.0&gt;, via Wikimedia Commons" href="https://commons.wikimedia.org/wiki/File:Viana_do_Castelo,_Portugal-1_(8610169337)_(cropped).jpg" target="_blank" rel="noopener noreferrer">
              <img alt="Viana do Castelo, Portugal-1 (8610169337) (cropped)" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Viana_do_Castelo%2C_Portugal-1_%288610169337%29_%28cropped%29.jpg/512px-Viana_do_Castelo%2C_Portugal-1_%288610169337%29_%28cropped%29.jpg" /></a>

            <p>Hoje, Viana do Castelo é conhecida por sua arquitetura elegante, incluindo o Santuário de Santa Luzia, uma imponente igreja situada no alto de uma colina com vista para a cidade, e o seu centro histórico pitoresco, repleto de praças charmosas e edifícios antigos. Além disso, a cidade é famosa pela produção de artesanato tradicional, como os coloridos lenços dos namorados e os trajes típicos usados durante festas tradicionais, como as festas de Nossa Senhora da Agonia.<br />
              Viana do Castelo é uma cidade que combina harmoniosamente sua rica herança histórica com a modernidade, oferecendo aos visitantes uma experiência encantadora de cultura, tradição e beleza natural, com suas praias deslumbrantes e paisagens deslumbrantes ao redor. Ela permanece como um tesouro cultural e turístico no coração de Portugal.</p>
          </div>
        </div>
      </div >
    </ div >
  );
};

export default Home;