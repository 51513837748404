import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './pages/Header';
import Home from './pages/Home';
import Restaurante from './pages/Restaurante';
import Lazer from './pages/Lazer';
import NoPage from './pages/NoPage';
import Industria from './pages/Industria'
import Contato from './pages/Contato'

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Home />} />
            <Route path="restaurante" element={<Restaurante />} />
            <Route path="lazer" element={<Lazer />} />
            <Route path="industria" element={<Industria />} />
            <Route path="contato" element={<Contato />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}
