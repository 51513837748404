import React from 'react';
import { FaMapMarker } from 'react-icons/fa';

const Industria = () => {
  return (
    <>
      <h2>Zonas Indústriais</h2>
      <div id="text-industria">
        <p>
          Acredito profundamente no papel crucial dos Parques Empresariais na
          melhoria da qualidade de vida das comunidades locais. Eles transcendem
          a simples ideia de áreas físicas; são verdadeiros polos de oportunidades
          e empreendedorismo. Viana do Castelo abriga quatro desses importantes polos industriais:
        </p>

        <div id="text-img-industria">
          <div>

            <p><FaMapMarker /><a href="https://maps.app.goo.gl/Uitsivds8fs654MD9" target="_blank" rel="noreferrer">Parque Empresarial da Praia Norte</a>
            </p>
            <p><FaMapMarker /><a href="https://maps.app.goo.gl/kTqeELkrBaDx1uieA" target="_blank" rel="noreferrer">Parque Empresarial da Meadela</a>
            </p>
            <p><FaMapMarker /><a href="https://maps.app.goo.gl/yA9UuLUkjTkpKrGH6" target="_blank" rel="noreferrer">Parque Empresarial de Lanheses</a>
            </p>
            <p><FaMapMarker /><a href="https://maps.app.goo.gl/CnYa8jRz9THjTvge9" target="_blank" rel="noreferrer">Parque Empresarial de Neiva</a>
            </p>
          </div>

          <div id='map'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d87765.93934363984!2d-8.916811371911676!3d41.69192626144857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0xd25b7cef6e86a73%3A0xfe4d167e5dd21dfd!2sViana%20do%20Castelo!3m2!1d41.691827499999995!2d-8.8344101!4m0!5e1!3m2!1spt-PT!2spt!4v1695576567098!5m2!1spt-PT!2spt"
              style={{ border: 0 }}
              loading="lazy"
              rel="noreferrer"
              title='Mapa Viana do Castelo'
            ></iframe>
          </div>

        </div>

        <p>
          A cidade de Viana do Castelo assume firmemente o compromisso de modernizar
          esses parques, reconhecendo o seu valor fundamental para um ordenamento territorial adequado.
        </p>
      </div>
    </>
  );
};

export default Industria;
