import "../component/style.scss"
import { FaLinkedin, FaGithub, FaWhatsapp, FaEnvelope } from 'react-icons/fa'

const Contato = () => {
  return (
    <>
      <div id="text-contato">
        <img id="logo-contacto" src="JN_TECH_WE.png" alt="JN Tech Web"></img>
        <div id="text-contato1">
          <div id="text-contato-link">

            <div>
              <FaGithub /> -
              <a href="https://github.com/DevOfInfinity" target="_blank" rel="noopener noreferrer">
                GitHub
              </a>
            </div>
            <div>
              <FaWhatsapp /> -
              <a href="https://wa.me/351935062949" target="_blank" rel="noopener noreferrer">
                WhatsApp
              </a>
            </div>
            <div>
              <FaEnvelope /> -
              <a href="mailto:joaolunascimento87@gmail.com">
                E-Mail
              </a>
            </div>
            <div>
              <FaLinkedin /> -
              <a href="https://www.linkedin.com/in/joão-nascimento-725785236/" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Contato;